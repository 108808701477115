import logo from 'src/assets/images/logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import UserInfo from './UserInfo';
import './header.modules.scss';
import ListMenu from './ListMenu';
import { getToken, isFirstLogin } from 'src/utils/auth';
import { unAuthRoutes } from 'src/utils/routes';

const Header = () => {
  const history = useNavigate();
  const location = useLocation();

  const handleGoHomePage = () => {
    if (getToken()) {
      if (isFirstLogin()) {
        history('/change-password');
      } else {
        history('/order');
      }
    } else {
      history('/login');
    }
  };

  const isUnLoginPath = unAuthRoutes.includes(location.pathname);

  return (
    <div id="header" className="flex header">
      <div className="flex items-center cursor-pointer mr-[24px]" onClick={handleGoHomePage}>
        <img className="header__logo" src={logo} alt="logo" />
      </div>
      {!isUnLoginPath && !!getToken() && (
        <>
          <ListMenu />
          <UserInfo />
        </>
      )}
    </div>
  );
};

export default Header;
