import { ACTION } from 'src/assets/dataUI';
import IconBrush from 'src/assets/icon/IconBrush';
import IconChangeOutline from 'src/assets/icon/IconChangeOutline';
import IconChangeWidth from 'src/assets/icon/IconChangeWidth';
import IconEnlargeReduce from 'src/assets/icon/IconEnlargeReduce';
import IconErase from 'src/assets/icon/IconErase';

export const MenuStepEraseBrush = ({ onClickErase, onClickBrush }: any) => [
  {
    id: ACTION.erase,
    isActive: false,
    name: '消しゴム',
    icon: <IconErase />,
    onClick: onClickErase,
  },
  {
    id: ACTION.brush,
    isActive: false,
    name: '修復ブラシ',
    icon: <IconBrush />,
    onClick: onClickBrush,
  },
];

export const MenyStepChangeSize = ({ onClickChangeWidth, onClickEnlarge }: any) => [
  {
    id: ACTION.changeWidth,
    isActive: false,
    name: '拡大・縮小',
    icon: <IconChangeWidth />,
    onClick: onClickChangeWidth,
  },
  {
    id: ACTION.enlargeReduce,
    isActive: false,
    name: '縁取りサイズ調整',
    icon: <IconChangeOutline />,
    onClick: onClickEnlarge,
  },
];

export const MenyStepChangeSizePanel = ({ onClickChangeWidth }: any) => [
  {
    id: ACTION.changeWidth,
    isActive: false,
    name: '拡大・縮小',
    icon: <IconChangeOutline />,
    onClick: onClickChangeWidth,
  },
];

export const MenuStep2AddPanel = ({ onClickEnlarge }: any) => [
  {
    id: ACTION.enlargeReduce,
    isActive: false,
    name: '拡大・縮小',
    icon: <IconEnlargeReduce />,
    onClick: onClickEnlarge,
  },
];
