import { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import styles from '../index.module.scss';
import { HEIGHT_STAGE, RANGE_SLIDER_STEP_SIZE, TypeLangFontTextEditor, WIDTH_STAGE } from 'src/assets/dataUI';
import classNames from 'classnames';
import { getTextMetrics } from '../../SvgMerge/utils';
import IconFont from 'src/assets/icon/IconFont';
import IconTextSpacing from 'src/assets/icon/IconTextSpacing';
import FontTextPicker from './FontPicker';
import MenuBottom from 'src/components/common/menuBottom';
import { Button, Slider } from 'antd';

type Props = {
  text: string;
  textStyle: any;
  setTextStyle: (val: any) => void;
  setStep: (val: any) => void;
  setTextLength: (val: any) => void;
  listFontEn: any;
  listFontJa: any;
  setTabFont: any;
  tabFont: number;
};

const TAB_TEXT = {
  font: 'font',
  color: 'color',
  letterSpacing: 'letterSpacing',
};

export default function Step2({
  text,
  textStyle,
  setTextStyle,
  setStep,
  setTextLength,
  listFontEn,
  listFontJa,
  setTabFont,
  tabFont,
}: Props) {
  const [tab, setTab] = useState(TAB_TEXT.font);
  const [langFont, setLangFont] = useState(tabFont);
  const [isNeedResize, setIsNeedResize] = useState(false);

  const menu = [
    {
      id: TAB_TEXT.font,
      isActive: tab === TAB_TEXT.font,
      name: 'フォント',
      icon: <IconFont />,
      onClick: () => {
        setTab(TAB_TEXT.font);
      },
    },
    {
      id: TAB_TEXT.color,
      isActive: tab === TAB_TEXT.color,
      name: 'フォントカラー',
      icon: <IconFont />,
      onClick: () => {
        setTab(TAB_TEXT.color);
      },
    },
    {
      id: TAB_TEXT.letterSpacing,
      isActive: tab === TAB_TEXT.letterSpacing,
      name: '文字の間隔',
      icon: <IconTextSpacing />,
      onClick: () => {
        setTab(TAB_TEXT.letterSpacing);
      },
    },
  ];

  useEffect(() => {
    setTabFont(langFont);
  }, [langFont]);

  const renderFeature = () => {
    switch (tab) {
      case TAB_TEXT.font:
        return (
          <div>
            <div className={styles.wrapBtnSelectLangFont}>
              <div
                className={classNames(styles.btnLangFont, [
                  {
                    [styles.btnLangFont__selected]: langFont === TypeLangFontTextEditor.JA,
                  },
                ])}
                onClick={() => {
                  setLangFont(TypeLangFontTextEditor.JA);
                }}
              >
                日本語
              </div>
              <div
                className={classNames(styles.btnLangFont, [
                  {
                    [styles.btnLangFont__selected]: langFont === TypeLangFontTextEditor.EN,
                  },
                ])}
                onClick={() => {
                  setLangFont(TypeLangFontTextEditor.EN);
                }}
              >
                English
              </div>
            </div>
            <FontTextPicker
              fontCurrent={textStyle}
              langFont={langFont}
              onPickFont={(item: any) =>
                setTextStyle({
                  ...textStyle,
                  fontFamily: item?.family,
                  name: item?.name,
                })
              }
              listFontEn={listFontEn}
              listFontJa={listFontJa}
            />
          </div>
        );
      case TAB_TEXT.color:
        return (
          <div>
            <HexColorPicker
              color={textStyle?.color}
              onChange={(e: any) => {
                setTextStyle({
                  ...textStyle,
                  color: e,
                });
              }}
              style={{ height: 220 }}
            />
          </div>
        );
      case TAB_TEXT.letterSpacing:
        return (
          <div>
            <div className={styles.letterSpacing}>
              <p className={styles.letterSpacingTitle}>文字の間隔</p>

              <Slider
                tooltip={{ formatter: null }}
                defaultValue={Number(textStyle?.letterSpacing?.slice(0, textStyle?.letterSpacing?.length - 2))}
                min={0}
                max={10}
                step={RANGE_SLIDER_STEP_SIZE}
                onChange={(e) => {
                  setTextStyle({
                    ...textStyle,
                    letterSpacing: e + 'px',
                  });
                }}
              />
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  // useEffect(() => {
  //   //calculate the text length here then check to know if we need to change the fontsize or not
  //   let textStats = getTextMetrics(text, textStyle?.fontFamily, textStyle?.fontSize, textStyle?.letterSpacing);
  //   let textLimit = WIDTH_STAGE - 70;

  //   if (isNeedResize && textStats.width + textStats.extra < textLimit - 3) {
  //     let currentFontSize = Number(textStyle?.fontSize.replace('px', ''));
  //     for (var i = currentFontSize; i < 100; i = i + 0.2) {
  //       let newMetric = getTextMetrics(text, textStyle?.fontFamily, i + 'px', textStyle?.letterSpacing);
  //       if (newMetric.width + newMetric.extra > textLimit - 3) {
  //         setTextStyle({
  //           ...textStyle,
  //           fontSize: i + 'px',
  //         });
  //         i = 101;
  //       }
  //     }
  //   }

  //   if (textStats.width + textStats.extra > textLimit) {
  //     setIsNeedResize(true);
  //     let currentFontSize = Number(textStyle?.fontSize.replace('px', ''));
  //     for (var i = currentFontSize; i > 0; i = i - 0.2) {
  //       let newMetric = getTextMetrics(text, textStyle?.fontFamily, i + 'px', textStyle?.letterSpacing);
  //       if (newMetric.width + newMetric.extra < textLimit) {
  //         setTextStyle({
  //           ...textStyle,
  //           fontSize: i + 'px',
  //         });
  //         i = -1;
  //       }
  //     }
  //   }
  // }, [textStyle?.letterSpacing, text]);

  return (
    <div className={styles.step2}>
      <p className={`${styles.showText} !mb-3`} style={{ width: WIDTH_STAGE, height: HEIGHT_STAGE }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Layer_1"
          data-name="Layer 1"
          width='100%'
          height={HEIGHT_STAGE}
          viewBox={`0 0 ${WIDTH_STAGE - 20} 200`}
        >
          <text
            className="text"
            fontFamily={textStyle?.fontFamily}
            fontSize={textStyle?.fontSize}
            letterSpacing={textStyle?.letterSpacing}
            fill={textStyle?.color}
            fontWeight="400"
            textAnchor="middle"
            x={(WIDTH_STAGE - 20) / 2}
            y="100"
            style={{
              animation: 'fontSize 2s',
            }}
          >
            {text}
          </text>
        </svg>
      </p>

      <div className="my-react-add-text flex justify-center mb-2">
        <div className="w-[500px] max-h-[220px]">{renderFeature()}</div>
      </div>

      <MenuBottom list={menu} />

      <div className="flex justify-center mt-3 gap-2">
        <Button
          className="button-form button-form-Submit !w-auto"
          onClick={() => {
            setStep((prev: number) => prev - 1);
          }}
        >
          {'<<前のステップへ'}
        </Button>
        <Button
          className="button-form button-form-Submit !w-auto"
          disabled={!text}
          onClick={() => {
            setStep((prev: number) => prev + 1);
          }}
        >
          {'次のステップへ>>'}
        </Button>
      </div>
    </div>
  );
}
