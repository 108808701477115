import React from 'react';
import styles from './index.module.scss';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import { TAB_BG } from 'src/components/features/superAdmin/EditorComponent/AddBackground';
import { HEIGHT_STAGE, WIDTH_STAGE } from 'src/assets/dataUI';

type Props = {
  list: any;
};

export default function MenuBottom({ list }: Props) {
  return (
    <div className={`${styles.menuBottom} ${list[0]?.sub ? styles.haveSub : ''} d-flex items-center justify-between ant-upload-picture-card-wrapper-bg`}>
      {list?.map((data: any) => {
        if (data?.id === TAB_BG.editBG) {
          return (
            <ImgCrop
              showGrid
              showReset
              rotationSlider
              aspect={WIDTH_STAGE / HEIGHT_STAGE}
              modalTitle="サムネイル画像のトリミング"
              modalOk="トリミング"
              modalCancel="キャンセル"
              resetText="リセット"
              key={data?.id}
            >
              <Upload
                listType="picture-card"
                showUploadList={false}
                multiple={false}
                maxCount={1}
                beforeUpload={(file) => data?.onClick(file)}
                className="uploadFileImage"
              >
                <div className={styles.itemMenuBottom}>
                  <div
                    className={`d-flex flex-column items-center justify-center ${styles.control} ${
                      data?.isActive ? styles.active : ''
                    }`}
                  >
                    {data?.icon}
                    <span className={styles.name}>{data?.name}</span>
                    {data?.sub && <span className={styles.sub}>{data?.sub}</span>}
                  </div>
                </div>
              </Upload>
            </ImgCrop>
          );
        }
        return (
          <button
            key={data?.id}
            className={styles.itemMenuBottom}
            onClick={data?.onClick}
            disabled={data?.disabled || false}
          >
            <div
              className={`d-flex flex-column items-center justify-center ${styles.control} ${
                data?.isActive ? styles.active : ''
              }`}
            >
              {data?.icon}
              <span className={styles.name}>{data?.name}</span>
              {data?.sub && <span className={styles.sub}>{data?.sub}</span>}
            </div>
          </button>
        );
      })}
    </div>
  );
}
