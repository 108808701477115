import React from 'react';
import { mergeSvgPath } from './utils';

const SvgMerge = ({
  backgroundImage,
  shapeData,
  outline,
  handleClick,
  hiddenIndex,
  onDragEnd,
  allowClickSvG = false,
  removeItem,
  onRotateEnd,
  extraState,
  isDeleteMode,
  isRestoreMode,
  isSingleShowMode,
  shapeSizeData,
  onDragDeleteEnd,
  onChangeCursorPosition,
  cursorSize,
  whiteBackground,
  isEndPhase,
}: any) => {
  return (
    <>
      {mergeSvgPath({
        backgroundImage,
        shapeData,
        outline,
        handleClick,
        hiddenIndex,
        onDragEnd,
        allowClickSvG,
        removeItem,
        onRotateEnd,
        extraState,
        isDeleteMode,
        isRestoreMode,
        isSingleShowMode,
        shapeSizeData,
        onDragDeleteEnd,
        onChangeCursorPosition,
        cursorSize,
        whiteBackground,
        isEndPhase,
      })}
    </>
  );
};

export default SvgMerge;
