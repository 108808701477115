const IconStandHole = ({ className }: any) => (
  <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.00004 17.5771C7.85773 17.5831 6.72646 17.3533 5.67704 16.9021C4.65648 16.4673 3.72848 15.8414 2.94304 15.058C2.1597 14.2726 1.53379 13.3446 1.09905 12.3241C0.647637 11.2743 0.417837 10.1427 0.424044 9.00003C0.41576 7.85395 0.645618 6.71866 1.09905 5.66605C1.53572 4.6498 2.16148 3.72573 2.94304 2.94303C3.72848 2.15968 4.65648 1.53378 5.67704 1.09903C6.72646 0.647758 7.85773 0.417976 9.00004 0.424046C10.1461 0.415733 11.2815 0.645576 12.334 1.09903C13.3503 1.53569 14.2744 2.16144 15.057 2.94303C15.8386 3.72573 16.4644 4.6498 16.901 5.66605C17.3545 6.71866 17.5843 7.85395 17.576 9.00003C17.5821 10.1423 17.3523 11.2736 16.901 12.323C16.4663 13.3436 15.8404 14.2716 15.057 15.057C14.2744 15.8386 13.3503 16.4644 12.334 16.901C11.2815 17.3549 10.1462 17.5851 9.00004 17.5771ZM9.00004 14.146C9.67785 14.1583 10.351 14.0315 10.9779 13.7735C11.6048 13.5155 12.1722 13.1318 12.645 12.646C13.1308 12.1732 13.5145 11.6058 13.7725 10.9789C14.0305 10.3519 14.1573 9.67888 14.145 9.00107C14.1573 8.32327 14.0305 7.65013 13.7725 7.02323C13.5145 6.39632 13.1308 5.82892 12.645 5.35605C12.1722 4.8703 11.6048 4.4866 10.9779 4.22861C10.351 3.97063 9.67785 3.84383 9.00004 3.85605C8.32224 3.84383 7.64912 3.97063 7.02221 4.22861C6.39531 4.4866 5.82792 4.8703 5.35504 5.35605C4.86929 5.82892 4.48558 6.39632 4.2276 7.02323C3.96961 7.65013 3.84282 8.32327 3.85504 9.00107C3.84282 9.67888 3.96961 10.3519 4.2276 10.9789C4.48558 11.6058 4.86929 12.1732 5.35504 12.646C5.82792 13.1318 6.39531 13.5155 7.02221 13.7735C7.64912 14.0315 8.32224 14.1583 9.00004 14.146Z"
      fill="black"
    />
  </svg>
);

export default IconStandHole;
