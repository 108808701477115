import * as React from "react";

const IconChangeOutline = ({ width = 24, height = 20, ...props }: any) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="22"
      height="17.6"
      rx="1.5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M5.53467 5.06995V4.06995H4.53467V5.06995H5.53467ZM9.87239 10.0841C10.2926 10.4425 10.9238 10.3924 11.2822 9.97223C11.6406 9.55203 11.5905 8.92085 11.1703 8.56245L9.87239 10.0841ZM6.53467 9.32328V5.06995H4.53467V9.32328H6.53467ZM5.53467 6.06995H10.5213V4.06995H5.53467V6.06995ZM4.88572 5.83078L9.87239 10.0841L11.1703 8.56245L6.18361 4.30911L4.88572 5.83078Z"
      fill="black"
    />
    <path
      d="M19.0013 14.45V15.45H20.0013V14.45H19.0013ZM14.6636 9.43578C14.2434 9.07738 13.6122 9.12747 13.2538 9.54767C12.8954 9.96787 12.9455 10.599 13.3657 10.9575L14.6636 9.43578ZM18.0013 10.1966V14.45H20.0013V10.1966H18.0013ZM19.0013 13.45H14.0147V15.45H19.0013V13.45ZM19.6503 13.6891L14.6636 9.43578L13.3657 10.9575L18.3524 15.2108L19.6503 13.6891Z"
      fill="black"
    />
  </svg>
);

export default IconChangeOutline;
