import React from 'react';
import GenerateShapeSvgByType from '../element/GenerateShapeSvg';
import { endDrag, setOffset, setTransform, makeDraggable } from '../controllers/TouchController';
import { GenerateOutlineForDragSvg } from '../element/GenerateOutlineForDragSvg';

const OutlineSvgPath = ({
  shapeData,
  outline,
  hiddenIndex,
  handleClick,
  allowClickSvG,
  onDragEnd,
  onRotateEnd,
  extraState,
  placeHolder,
  isEndPhase,
}: any) => {
  const shapeInfo = shapeData[0];

  return (
    <g filter={placeHolder ? 'url(#outline_minimun)' : 'url(#outline)'} strokeWidth={outline?.outlinePadding}>
      <GenerateOutlineForDragSvg shapeInfo={shapeInfo} isStepOutline={true} />

      <g id={placeHolder ? 'svg_outline_shape_size' : 'svg_outline_shape'}>
        {shapeData?.map((shapeInfo: any, index: any) => {
          if (hiddenIndex === index) {
            return <></>;
          } else {
            return (
              <>
                <GenerateShapeSvgByType
                  shapeInfo={
                    placeHolder
                      ? {
                          ...shapeInfo,
                          outlinePadding: 0,
                        }
                      : shapeInfo
                  }
                  outline={outline}
                  drawOutline={
                    (placeHolder && [99, 100].includes(shapeInfo?.type)) || (placeHolder && shapeInfo?.cate === 'panel')
                      ? false
                      : true
                  }
                  handleClick={(e: any) => {
                    if (allowClickSvG) {
                      if (hiddenIndex > -1) {
                        endDrag({
                          evt: e,
                          index: hiddenIndex,
                          onDragEnd,
                          onRotateEnd,
                          extraState,
                        });
                        handleClick(-1);
                      } else {
                        handleClick(index);
                        setOffset({
                          x: 0,
                          y: 0,
                        });
                        if (extraState) {
                          extraState.offsetEditing = {
                            x: 0,
                            y: 0,
                          };
                          extraState.transformEditing = 0;
                        }
                        setTransform(0);
                        makeDraggable(index, extraState);
                      }
                    }
                  }}
                  index={index}
                  isEndPhase={isEndPhase}
                />
              </>
            );
          }
        })}
      </g>
    </g>
  );
};

export default OutlineSvgPath;
