const IconBack = ({ className }: any) => (
  <svg className={className} width={29} height={30} viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.797 14.399C28.797 6.44665 22.3506 0 14.3985 0C6.44643 0 0 6.44665 0 14.399V15.221C0 23.1733 6.44643 29.62 14.3985 29.62C22.3506 29.62 28.797 23.1733 28.797 15.221V14.399Z"
      fill="white"
    />
    <path
      d="M18.1009 14.8089C18.101 14.6984 18.0787 14.5889 18.0354 14.4872C17.9921 14.3854 17.9287 14.2935 17.8489 14.2169L12.9119 9.27993C12.7504 9.1334 12.5373 9.05703 12.3195 9.06762C12.1016 9.07822 11.897 9.17491 11.7504 9.33643C11.6039 9.49794 11.5275 9.71106 11.5381 9.92888C11.5487 10.1467 11.6454 10.3514 11.8069 10.4979L16.1189 14.8089L11.7489 19.1759C11.6046 19.3321 11.5263 19.5381 11.5304 19.7507C11.5346 19.9634 11.6209 20.1661 11.7713 20.3165C11.9217 20.4669 12.1245 20.5532 12.3371 20.5574C12.5497 20.5616 12.7557 20.4833 12.9119 20.3389L17.8489 15.4019C17.9287 15.3253 17.9921 15.2334 18.0354 15.1317C18.0787 15.0299 18.101 14.9205 18.1009 14.8099V14.8089Z"
      fill="#3AC435"
      stroke="#3AC435"
    />
  </svg>
);

export default IconBack;
