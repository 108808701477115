import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import Step1 from './components/Step1';
import Step2AddStamp from './components/Step2';
import Step3AddStamp from './components/Step3';
import { Button } from 'antd';
import { getElementSvgFile, nodeToObject } from 'src/utils';
import { generateBlobFromBase64, revertPathStringByScale } from '../SvgMerge/utils';
import { generateSvgFromUrl } from '../SvgMerge/ImageToSvg';
import {
  HEIGHT_STAGE,
  LIMIT_HEIGHT_MIN,
  LIMIT_WIDTH_MIN,
  OUTLINE_PADDING_DEFAULT,
  WIDTH_PANEL_STAMP_ITEM,
  WIDTH_STAGE,
} from 'src/assets/dataUI';
import { changeColInUri } from '../utils';

export default function AddStamp({
  handleSetFeature,
  editMode,
  baseSvgContent,
  defaultStep,
}: {
  handleSetFeature: () => void;
  editMode: any;
  baseSvgContent: any;
  defaultStep: any;
  feature: string;
}) {
  const [step, setStep] = useState<number>(editMode ? defaultStep : 1);
  const [stamp, setStamp] = useState<any>();
  const [stampOrigin, setStampOrigin] = useState<any>();
  const [svgContent, setSvgContent] = useState<any>(
    editMode && baseSvgContent ? baseSvgContent?.attrs?.shapeData[0] : false
  );
  const [svgContentOrigin, setSvgContentOrigin] = useState<any>(
    editMode && baseSvgContent ? { src: baseSvgContent?.attrs?.shapeData[0]?.image_url } : false
  );
  const [width, setWidth] = useState<any>(WIDTH_PANEL_STAMP_ITEM);
  const [deletePath, setDeletePath] = useState([]);
  const [newImage, setNewImage] = useState<any>();
  const [imageBackUp, setImageBackUp] = useState<any>();
  const [resetFromStep1, setResetFromStep1] = useState<any>(false);
  const [imageSize, setImageSize] = useState<any>({});
  const [isDeleteReCover, setIsDeleteReCover] = useState(false);
  const [initImage, setInitImage] = useState(false);
  const [imageObject, setImageObject] = useState<any>(false);
  const [currentSelectStamp, setCurrentSelectStamp] = useState<any>(false);
  const [urlSvg, setUrlSvg] = useState('');
  const [isPng, setIsPng] = useState(false);
  const [pngBase64, setPngBase64] = useState('');

  useEffect(() => {
    if (imageObject?.file_path_url && imageObject?.file_path_url.endsWith('.png') && imageObject?.temporary_url) {
      setIsPng(true);
      const image = new Image();
      image.crossOrigin = 'Anonymous';
      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx: any = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0);
        const imageBase64Data = canvas.toDataURL('image/png');
        generateSvgFromUrl(imageBase64Data, (svg: any) => {
          const blob = new Blob([svg], { type: 'image/svg+xml' });
          const DOMURL = self.URL || self.webkitURL || self;
          const url = DOMURL.createObjectURL(blob);
          //setUrlSvg(url);
        });
        setPngBase64(imageBase64Data);
      };
      image.src = imageObject?.temporary_url;
    } else {
      setIsPng(false);
      setUrlSvg(imageObject?.file_path_url);
    }
  }, [imageObject]);

  const MAX_WIDTH_IMAGE = WIDTH_STAGE - 50;
  const MAX_HEIGHT_IMAGE = HEIGHT_STAGE - 50;

  useEffect(() => {
    if (isPng && pngBase64) {
      let blobIn = generateBlobFromBase64({
        base64String: pngBase64,
      });

      var DOMURL = self.URL || self.webkitURL || self;
      var url: any = DOMURL.createObjectURL(blobIn);

      changeColInUri({
        base64: pngBase64,
        colto: '#000000',
        callBack: (newUrl: any) => {
          generateSvgFromUrl(newUrl, (svg: any) => {
            if (svg) {
              const parser = new DOMParser();
              const svgDoc = parser.parseFromString(svg, 'image/svg+xml');

              const img = new Image();
              img.onload = () => {
                let SCALE_BY_WIDTH = MAX_WIDTH_IMAGE / img.width;
                let SCALE_BY_HEIGHT = MAX_HEIGHT_IMAGE / img.height;

                let scaleRatio = 1;

                if (SCALE_BY_WIDTH > SCALE_BY_HEIGHT) {
                  scaleRatio = SCALE_BY_HEIGHT;
                } else {
                  scaleRatio = SCALE_BY_WIDTH;
                }

                if (svgDoc && scaleRatio) {
                  const svgContent = nodeToObject(svgDoc);
                  let filteredData = svgContent?.children[0]?.children;

                  filteredData = filteredData.map((pathInfo: any) => {
                    return {
                      ...pathInfo,
                      attributes: {
                        ...pathInfo?.attributes,
                        d: revertPathStringByScale({
                          pathString: pathInfo?.attributes?.d,
                          scale: {
                            base: 1 / scaleRatio,
                          },
                        }),
                      },
                    };
                  });

                  setSvgContent({
                    ...svgContent?.children[0],
                    children: filteredData,
                    image_url: pngBase64,
                    cate: 'image',
                    outlinePadding: OUTLINE_PADDING_DEFAULT,
                    scale: {
                      base: 1,
                    },
                    attributes: {
                      ...svgContent?.children[0]?.attributes,
                      width: img?.width * scaleRatio,
                      height: img?.height * scaleRatio,
                      viewBox: `0 0 ${img?.width * scaleRatio} ${img?.height * scaleRatio}`,
                    },
                    width: img?.width * scaleRatio,
                    height: img?.height * scaleRatio,
                    transform: {
                      x: (WIDTH_STAGE - img?.width * scaleRatio) / 2,
                      y: (HEIGHT_STAGE - img?.height * scaleRatio) / 2,
                    },
                    original: false,
                    rotate: {
                      a: 0,
                      x: 0,
                      y: 0,
                      baseScale: 1,
                    },
                  });

                  setImageSize({
                    x: 0,
                    y: 0,
                    width: img?.width * scaleRatio,
                    height: img?.height * scaleRatio,
                    baseWidth: Number(img?.width),
                    baseHeight: Number(img?.height),
                  });
                }
                setDeletePath([]);
              };
              img.src = pngBase64;
            }
          });
        },
      });
      setImageBackUp({ src: pngBase64 });
    }
  }, [pngBase64, isPng]);

  useEffect(() => {
    if (initImage && urlSvg) {
      //we try to add them out here
      getElementSvgFile(urlSvg).then((res: any) => {
        const newRes = {
          ...res,
          attributes: {
            ...res?.attributes,
            width: Number(res?.attributes?.viewBox?.split(' ')[2]),
            height: Number(res?.attributes?.viewBox?.split(' ')[3]),
          },
        };

        let widthRate = (WIDTH_STAGE - 200) / Number(newRes?.attributes?.width);
        let heightRate = (HEIGHT_STAGE - 200) / Number(newRes?.attributes?.height);

        let baseRate = widthRate;
        if (heightRate < baseRate) {
          baseRate = heightRate;
        }

        var img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
          var canvas: any = document.createElement('CANVAS');
          var ctx = canvas.getContext('2d');
          var dataURL;
          canvas.height = img.height;
          canvas.width = img.width;
          ctx.drawImage(img, 0, 0);
          dataURL = canvas.toDataURL();

          let newChild = newRes?.children?.filter((pathInfo: any) => {
            if (pathInfo?.tagName == 'path') {
              return true;
            } else {
              return false;
            }
          });

          let newPath = newChild?.map((pathInfo: any) => {
            return {
              ...pathInfo,
              attributes: {
                ...pathInfo?.attributes,
                d: revertPathStringByScale({
                  pathString: pathInfo?.attributes?.d,
                  scale: {
                    base: 1 / baseRate,
                  },
                  isNeedReplace: !isPng,
                }),
              },
            };
          });

          setSvgContent({
            type: 9,
            ...newRes,
            children: newPath,
            width: Number(newRes?.attributes?.width) * baseRate,
            height: Number(newRes?.attributes?.height) * baseRate,
            size_image: {
              width: Number(newRes?.attributes?.width) * baseRate,
              height: Number(newRes?.attributes?.height) * baseRate,
            },
            scale: {
              base: 1,
            },
            original: true,
            transform: {
              x: (WIDTH_STAGE - Number(newRes?.attributes?.width) * baseRate) / 2,
              y: (HEIGHT_STAGE - Number(newRes?.attributes?.height) * baseRate) / 2,
            },
            image_url: isPng ? pngBase64 : dataURL,
          });

          if (Number(newRes?.attributes?.width) < LIMIT_WIDTH_MIN) {
            setImageSize({
              x: 0,
              y: 0,
              width: Number(newRes?.attributes?.width) * baseRate,
              height: Number(newRes?.attributes?.height) * baseRate,
              baseWidth: LIMIT_WIDTH_MIN,
              baseHeight: (LIMIT_WIDTH_MIN * Number(newRes?.attributes?.height)) / Number(newRes?.attributes?.width),
            });
          } else {
            if (Number(newRes?.attributes?.height) < LIMIT_HEIGHT_MIN) {
              setImageSize({
                x: 0,
                y: 0,
                width: Number(newRes?.attributes?.width) * baseRate,
                height: Number(newRes?.attributes?.height) * baseRate,
                baseWidth: (LIMIT_HEIGHT_MIN * Number(newRes?.attributes?.width)) / Number(newRes?.attributes?.height),
                baseHeight: LIMIT_HEIGHT_MIN,
              });
            } else {
              setImageSize({
                x: 0,
                y: 0,
                width: Number(newRes?.attributes?.width) * baseRate,
                height: Number(newRes?.attributes?.height) * baseRate,
                baseWidth: Number(newRes?.attributes?.width),
                baseHeight: Number(newRes?.attributes?.height),
              });
            }
          }
          setImageBackUp({ src: dataURL });
        };

        img.src = urlSvg;
      });

      setInitImage(false);
    }
  }, [initImage, urlSvg]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            stamp={stamp}
            setStamp={setStamp}
            setStampOrigin={setStampOrigin}
            setStep={setStep}
            setImageBackUp={setImageBackUp}
            svgContent={svgContent}
            setNewImage={setNewImage}
            newImage={newImage}
            imageSize={imageSize}
            setImageSize={setImageSize}
            handleSetFeature={handleSetFeature}
            imageObject={imageObject}
            setImageObject={setImageObject}
            currentSelectStamp={currentSelectStamp}
            setCurrentSelectStamp={setCurrentSelectStamp}
            setInitImage={setInitImage}
          />
        );
      case 2:
        return (
          <Step2AddStamp
            svgContent={svgContent}
            svgContentOrigin={svgContentOrigin}
            setStep={setStep}
            width={width}
            setWidth={setWidth}
            deletePath={deletePath}
            setDeletePath={setDeletePath}
            imageSize={imageSize}
            setImageSize={setImageSize}
            setNewImage={setNewImage}
            newImage={newImage}
            imageBackUp={imageBackUp}
            setSvgContent={setSvgContent}
            setSvgContentOrigin={setSvgContentOrigin}
            isDeleteReCover={isDeleteReCover}
            setIsDeleteReCover={setIsDeleteReCover}
            isPng={isPng}
          />
        );
      case 3:
        return (
          <Step3AddStamp
            svgContent={svgContent}
            svgContentOrigin={svgContentOrigin}
            width={width}
            setWidth={setWidth}
            newImage={newImage}
            setNewImage={setNewImage}
            imageSize={imageSize}
            setStep={setStep}
            isDeleteReCover={isDeleteReCover}
            setIsDeleteReCover={setIsDeleteReCover}
            handleSetFeature={handleSetFeature}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className={styles.wrapRenderStep}>
      <Button
        className="button-form !w-auto mb-3"
        onClick={() => {
          handleSetFeature();
        }}
      >
        キャンセル
      </Button>

      {renderStep()}
    </div>
  );
}
