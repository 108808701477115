import { useRef, useState } from 'react';
import styles from './index.module.scss';
import MenuBottom from 'src/components/common/menuBottom';
import IconAddBackground from 'src/assets/icon/IconAddBackground';
import IconEditBackground from 'src/assets/icon/IconEditBackground';
import { HexColorPicker } from 'react-colorful';
import { HEIGHT_STAGE, WIDTH_STAGE } from 'src/assets/dataUI';
import { Button, message } from 'antd';

export const TAB_BG = {
  addBG: 'addBG',
  editBG: 'editBG',
};

export default function AddBackground({
  handleSetFeature,
  setBackgroundImage,
}: {
  handleSetFeature: () => void;
  setBackgroundImage: (val: any) => void;
}) {
  const divRef = useRef(null);
  const [tab, setTab] = useState<string>();
  const [background, setBackground] = useState<any>({
    backgroundImage: undefined,
    backgroundColor: undefined,
  });

  const menu = [
    {
      id: TAB_BG.editBG,
      isActive: tab === TAB_BG.addBG,
      name: '背景追加',
      icon: <IconAddBackground />,
      onClick: (file: any) => {
        setTab(TAB_BG.addBG);
        beforeUpload(file);
      },
    },
    {
      id: TAB_BG.addBG,
      isActive: tab === TAB_BG.editBG,
      name: '背景カラー',
      icon: <IconEditBackground />,
      onClick: () => {
        setTab(TAB_BG.editBG);
      },
    },
  ];

  const beforeUpload = async (file: any) => {
    const isLt5MB = file.size / 1024 / 1024 < 5;
    if (RegExp(/(jpg|jpeg|png)/i).test(`${file.type} ${file?.name}`)) {
      if (!isLt5MB) {
        message.error('ファイルサイズが5MBを超えています');
        return;
      } else {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          setBackground({
            backgroundImage: `url("${e.target.result}")`,
            backgroundColor: undefined,
          });
        };
        reader.readAsDataURL(file);
      }
    } else {
      message.error('アップロードするファイルは「JPG, JPEG, PNG」のいずれかの形式にしてください');
    }
    setTab(undefined);
    return false;
  };

  const handleGenerateImageData = () => {
    if (divRef.current) {
      const divStyle = window.getComputedStyle(divRef.current);
      const backgroundImage = divStyle.getPropertyValue('background-image');
      const width = WIDTH_STAGE;
      const height = HEIGHT_STAGE;
      const canvas = document.createElement('canvas');
      const context: any = canvas.getContext('2d');
      context.imageSmoothingEnabled = false;
      canvas.width = width;
      canvas.height = height;

      if (backgroundImage && backgroundImage !== 'none') {
        let imageUrl = backgroundImage.replace(/url\(['"]?(.*?)['"]?\)/i, '$1');
        imageUrl = imageUrl.replace(/^url\(['"]?/, '').replace(/['"]?\)$/, '');

        const imageSrc = new Image();
        imageSrc.crossOrigin = 'anonymous';
        imageSrc.onload = () => {
          const aspectRatio = imageSrc.width / imageSrc.height;
          const canvasAspectRatio = width / height;
          let imgWidth = width;
          let imgHeight = height;

          if (aspectRatio < canvasAspectRatio) {
            imgWidth = width;
            imgHeight = width / aspectRatio;
          } else {
            imgWidth = height * aspectRatio;
            imgHeight = height;
          }

          const xOffset = -(imgWidth - width) / 2;
          const yOffset = -(imgHeight - height) / 2;

          context.drawImage(imageSrc, xOffset, yOffset, imgWidth, imgHeight);
          const dataUrl = canvas.toDataURL('image/jpeg');

          const newImageImage = dataUrl;
          setBackgroundImage(newImageImage);
          handleSetFeature();
        };
        imageSrc.src = imageUrl;
      } else {
        context.fillStyle = background?.backgroundColor;
        context.fillRect(0, 0, width, height);
        const dataUrl = canvas.toDataURL('image/jpeg');
        const newImageColor = dataUrl;
        setBackgroundImage(newImageColor);
        handleSetFeature();
      }
    }
  };

  return (
    <div className={styles.wrapRenderStep}>
      <Button
        className="button-form !w-auto mb-3"
        onClick={() => {
          handleSetFeature();
        }}
      >
        キャンセル
      </Button>

      <div className="flex justify-center">
        <div
          ref={divRef}
          className={styles.wrapImage}
          style={{
            width: WIDTH_STAGE,
            height: HEIGHT_STAGE,
            ...background,
          }}
        />
      </div>

      <div className="my-react-add-text flex justify-center mb-2">
        <div className="w-[500px] max-h-[220px]">
          {tab === TAB_BG.editBG && (
            <div className="my-react-add-text flex justify-center h-[150px] mb-2 mt-5">
              <HexColorPicker
                color={background?.backgroundColor}
                onChange={(e: any) => {
                  setBackground({
                    backgroundImage: 'unset',
                    backgroundColor: e,
                  });
                }}
              />
            </div>
          )}
        </div>
      </div>

      <MenuBottom list={menu} />

      <div className="flex justify-center gap-2 mt-3">
        <Button className="button-form button-form-Submit !w-auto" onClick={handleSetFeature}>
          {'<<詳細画面へ戻る'}
        </Button>
        <Button
          className="button-form button-form-Submit !w-auto"
          disabled={!background?.backgroundImage && !background?.backgroundColor}
          onClick={handleGenerateImageData}
        >
          OK
        </Button>
      </div>
    </div>
  );
}
