import styles from '../index.module.scss';
import useItem from 'src/hook/useItem';
import { nanoid } from 'nanoid';
import {
  ACRYLIC_WIDTH_MAX,
  ACRYLIC_WIDTH_MIN,
  HEIGHT_STAGE,
  OUTLINE_PADDING_DEFAULT,
  WIDTH_STAGE,
} from 'src/assets/dataUI';
import { useEffect, useState } from 'react';
import convert from 'convert-length';
import { useFetchParts } from 'src/api/useFetchParts';
import { clamp } from 'src/utils';
import { Button } from 'antd';
import { calculateSvgSize, revertCalculationBeforeTransform } from '../../SvgMerge/utils';
import SvgMerge from '../../SvgMerge';
import { SOCKET_LIST } from '../constants/socketList';

type Props = {
  socket: any;
  setSocket: (val: any) => void;
  svgContent: any;
  handleSetFeature: any;
  setSvgContent: any;
  infoGeneralTemplate: any;
};

export default function Step1({
  socket,
  setSocket,
  svgContent,
  handleSetFeature,
  setSvgContent,
  infoGeneralTemplate,
}: Props) {
  const { createItem } = useItem();
  const { data } = useFetchParts([3]);
  const [width, setWidth] = useState<any>();
  const [height, setHeight] = useState<any>();
  const [socketSpec, setSocketSpec] = useState<any>();
  const [shapeSize, setShapeSize] = useState<any>();
  const ratioHeightWitdh = (svgContent?.attributes?.height ?? 50) / (svgContent?.attributes?.width ?? 50);

  useEffect(() => {
    const acryWidth = clamp(infoGeneralTemplate?.width ?? 0, ACRYLIC_WIDTH_MIN, ACRYLIC_WIDTH_MAX);
    const result = convert(acryWidth, 'mm', 'px');
    if (socketSpec) {
      setWidth(Number(convert(socketSpec?.width, 'mm', 'px') * (WIDTH_STAGE / result) ?? 50));
    } else {
      const widthSocketMM = Number(socket?.width);
      if (widthSocketMM) {
        setWidth(Number(convert(widthSocketMM, 'mm', 'px') * (WIDTH_STAGE / result) ?? 50));
        setHeight(Number(convert(widthSocketMM, 'mm', 'px') * ratioHeightWitdh * (WIDTH_STAGE / result) ?? 50));
      }
    }
  }, [svgContent, socket, socketSpec, infoGeneralTemplate]);

  useEffect(() => {
    let shapeSize = calculateSvgSize({
      idToCheck: 'svg_outline_shape',
      baseShapeInfo: svgContent,
      isText: false,
    });
    shapeSize = revertCalculationBeforeTransform({
      transform: {
        x: (WIDTH_STAGE - width) / 2,
        y: (HEIGHT_STAGE - width) / 2,
      },
      scale: {
        base: width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]),
      },
      rotate: {
        a: 0,
        x: Number(svgContent?.attributes?.viewBox?.split(' ')[2]) / 2,
        y: Number(svgContent?.attributes?.viewBox?.split(' ')[3]) / 2,
        baseScale: width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]),
      },
      outputSvgSize: shapeSize,
    });
    setShapeSize(shapeSize);
  }, [svgContent, socketSpec]);

  const onSubmitFinal = () => {
    createItem({
      id: nanoid(),
      attrs: {
        zIndex: 1,
        shapeData: [
          socketSpec
            ? {
                ...socketSpec,
                shapeSize: shapeSize,
                outlinePadding: 0,
                scale: {
                  base: 1,
                },
                width: OUTLINE_PADDING_DEFAULT + width,
              }
            : {
                cate: 'socket',
                type: 8,
                ...svgContent,
                outlinePadding:
                  OUTLINE_PADDING_DEFAULT / (width / Number(svgContent?.attributes?.viewBox?.split(' ')[2])),
                transform: {
                  x: (WIDTH_STAGE - width) / 2,
                  y: (HEIGHT_STAGE - height) / 2,
                },
                scale: {
                  base: width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]),
                },
                width: width,
                height: height,
                rotate: {
                  a: 0,
                  x: Number(svgContent?.attributes?.viewBox?.split(' ')[2]) / 2,
                  y: Number(svgContent?.attributes?.viewBox?.split(' ')[3]) / 2,
                  baseScale: width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]),
                },
                shapeSize: shapeSize,
                strokeLinejoin: false,
              },
        ],
      },
    });

    handleSetFeature();
  };

  return (
    <>
      <div className={styles.step1}>
        {data?.data[3]?.map((item: any) => {
          return (
            <div className={styles.socketType} key={item.title}>
              <p className={styles.title}>{item.name}</p>

              <div className={styles.sockets}>
                {item.parts.map((i: any) => {
                  return (
                    <div
                      className={`${styles.socketItem} ${i.id === socket?.id ? styles.socketItemBorder : ''}`}
                      key={i.key}
                      onClick={() => {
                        setSocket(i);
                        setSocketSpec(undefined);
                      }}
                    >
                      <div>
                        <div className={styles.wrapImage}>
                          <img src={i?.file_path_url} alt={i?.file_name} width={50} height={45} />
                        </div>
                        <p>差し幅 {i?.memo}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}

        <div className={styles.socketType}>
          <p className={styles.title}>アクリルスタンド差込み口</p>

          <div className={styles.sockets}>
            {SOCKET_LIST?.map((i: any, index: number) => {
              return (
                <div
                  className={`${styles.socketItem} ${i.id === socketSpec?.id ? styles.socketItemBorder : ''}`}
                  key={i.key}
                  onClick={() => {
                    setSocketSpec(i);
                    setSvgContent(undefined);
                    setSocket(undefined);
                  }}
                >
                  <div>
                    <div className={styles.wrapImage}>
                      <img src={'/socket-spec.svg'} alt="socketSpec" width={i?.widthMM * 1.5} height={45} />
                    </div>
                    <p>内径 {i?.widthMM}mm</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div
        style={{
          visibility: 'hidden',
          position: 'absolute',
        }}
      >
        {(svgContent || socketSpec) && (
          <SvgMerge
            shapeData={[
              socketSpec
                ? {
                    ...socketSpec,
                  }
                : {
                    type: 8,
                    ...svgContent,
                    outlinePadding:
                      OUTLINE_PADDING_DEFAULT / (width / Number(svgContent?.attributes?.viewBox?.split(' ')[2])),
                    transform: {
                      x: (WIDTH_STAGE - width) / 2,
                      y: (HEIGHT_STAGE - height) / 2,
                    },
                    scale: {
                      base: width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]),
                    },
                    width: width,
                    height: height,
                    rotate: {
                      a: 0,
                      x: Number(svgContent?.attributes?.viewBox?.split(' ')[2]) / 2,
                      y: Number(svgContent?.attributes?.viewBox?.split(' ')[3]) / 2,
                      baseScale: width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]),
                    },
                    shapeSize: shapeSize,
                    strokeLinejoin: false,
                  },
            ]}
            outline={{
              showOutline: true,
            }}
          />
        )}
      </div>

      <div className="flex justify-center gap-2 mt-3">
        <Button
          className="button-form button-form-Submit !w-auto"
          onClick={() => {
            handleSetFeature();
          }}
        >
          {'<<詳細画面へ戻る'}
        </Button>
        <Button
          className="button-form button-form-Submit !w-auto"
          onClick={() => onSubmitFinal()}
          disabled={!socket && !socketSpec}
        >
          OK
        </Button>
      </div>
    </>
  );
}
