import React from 'react';
import styles from './index.module.scss';
import IconEditorText from 'src/assets/icon/IconEditorText';
import IconEditorIcon from 'src/assets/icon/IconEditorIcon';
import { FEATURE } from 'src/assets/dataUI';
import IconEditorPanel from 'src/assets/icon/IconEditorPanel';
import IconEditorImage from 'src/assets/icon/IconEditorImage';
import IconEditorAddBG from 'src/assets/icon/IconEditorAddBG';
import IconStandHole from 'src/assets/icon/IconStandHole';
import IconSoket from 'src/assets/icon/IconSoket';

export type SettingBarProps = {
  setFeature: (val: string) => void;
  setIsAddItem: (val: any) => void;
};

export const widgetList = [
  {
    id: 'text',
    name: 'テキスト',
    icon: <IconEditorText />,
    minWidth: '52px',
    feature: FEATURE.text,
  },
  {
    id: 'icon',
    name: 'スタンプ',
    icon: <IconEditorIcon />,
    minWidth: '52px',
    feature: FEATURE.icon,
  },
  {
    id: 'panel',
    name: 'クリアパネル',
    icon: <IconEditorPanel />,
    minWidth: '78px',
    feature: FEATURE.panel,
  },
  {
    id: 'image',
    name: '画像',
    icon: <IconEditorImage />,
    minWidth: '26px',
    feature: FEATURE.image,
  },
  {
    id: 'background',
    name: '背景',
    icon: <IconEditorAddBG />,
    minWidth: '26px',
    feature: FEATURE.background,
  },
  {
    id: 'hole',
    name: 'キーホルダー・スタンド穴',
    icon: <IconStandHole />,
    minWidth: '',
    feature: FEATURE.hole,
  },
  {
    id: 'socket',
    name: 'アクスタ用ツメ・差込口',
    icon: <IconSoket />,
    minWidth: '',
    feature: FEATURE.socket,
  },
];

const SettingBar: React.FC<SettingBarProps> = ({ setFeature, setIsAddItem }) => {
  return (
    <div className={`${styles.footerEditor} d-flex items-center justify-between`}>
      {widgetList.map((data) => (
        <div
          key={`widget-${data.id}`}
          className={styles.itemMenuEditor}
          style={{ minWidth: data.minWidth }}
          onClick={() => {
            setFeature(data.feature);
            setIsAddItem(true);
          }}
        >
          <div className={`d-flex flex-column items-center justify-center ${styles.control}`}>
            {data.icon}
            <span className={styles.name}>{data.name}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SettingBar;
