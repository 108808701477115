import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import IconArrowLeft from 'src/assets/icon/IconArrowLeft';
import Loading from 'src/components/common/loading';
import 'src/components/features/superAdmin/detailContent.modules.scss';
import { Button, message, Form, Input, Spin, Select, Progress } from 'antd';
import IconTrash from 'src/assets/icon/IconTrash';
import ModalConfirm from 'src/components/common/modal/modalConfirm';
import { useMutation } from 'react-query';
import IconEdit from 'src/assets/icon/IconEdit';
import NotFoundPage from 'src/pages/notFoundPage';
import { useDetailTemplate } from 'src/api/useDetailTemplate';
import { useDeleteTemplate } from 'src/api/useDeleteTemplate';
import { Image } from 'antd';
import { useListSelectAcrylicPlate } from 'src/api/useListSelectAcrylicPlate';
import { useListSizeAcrylicPlate } from 'src/api/useListSizeAcrylicPlate';
import IconPreviewImage from 'src/assets/icon/IconPreviewImage';
import IconDownloadImage from 'src/assets/icon/IconDownloadImage';
import ModalPreviewFile from 'src/components/common/modal/modalPreviewFile';
import { handleDownload } from 'src/utils';

const TemplateDetailComponent = () => {
  const [antForm] = Form.useForm();
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [svgImage, setSvgImage] = useState<any>(null);
  const { data: detailData, isLoading: isLoadingDetail, refetch, isError } = useDetailTemplate(Number(id));
  const { data: dataSelectAcrylicPlate } = useListSelectAcrylicPlate();
  const { data: dataSelectSizeAcrylicPlate } = useListSizeAcrylicPlate(
    detailData?.acrylic_plate_size?.acrylic_plate_id
  );
  const ratioImageDesign =
    Number(detailData?.acrylic_plate_size?.height) / Number(detailData?.acrylic_plate_size?.width);
  const heightImageDesign = 300 * ratioImageDesign;
  const newDataSelectAcrylicPlate = dataSelectAcrylicPlate?.map((item: any) => {
    return {
      value: item?.id,
      label: item?.title,
    };
  });
  const newDataSelectSizeAcrylicPlate = dataSelectSizeAcrylicPlate?.map((item: any) => {
    return {
      value: item?.id,
      label: item?.size_title,
    };
  });

  const { isLoading: isLoadingDelete, mutate: onDelete } = useMutation((id: number | string) => useDeleteTemplate(id), {
    onSuccess: async () => {
      refetch();
      message.success('テンプレート削除は成功しました');
      setIsOpenModalDelete(false);
      history('/product/templates');
    },
    onError: () => {
      message.error('システムにエラーが発生しました。後でもう一度お試しください');
    },
  });
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);
  const [isOpenModalPreviewImage, setIsOpenModalPreviewImage] = useState<boolean>(false);

  const handleGoBack = () => {
    history('/product/templates');
  };

  const handleConfirmDelete = () => {
    if (id) {
      onDelete(id);
    }
  };

  const handleCloseModalDelete = () => {
    setIsOpenModalDelete(false);
  };

  const handleOpenModalDelete = () => {
    setIsOpenModalDelete(true);
  };

  const handleGoToEditTemplate = () => {
    history(`/product/templates/edit/${id}`);
  };

  useEffect(() => {
    const imageUrlRender = detailData?.image_url;
    if (imageUrlRender) {
      try {
        fetch(imageUrlRender, {
          method: 'GET',
          headers: {
            pragma: 'no-cache',
            'cache-control': 'no-cache',
          },
        })
          .then((res) => res.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.readAsText(blob);
            reader.onloadend = () => {
              const svgString = reader?.result as any;
              setSvgImage(svgString);
            };
          });
      } catch (err) {}
    }
  }, [detailData?.image_url]);

  if ((!detailData || Object.keys(detailData).length === 0 || isError) && !isLoadingDetail) {
    return <NotFoundPage />;
  }

  return (
    <>
      {isLoadingDetail ? (
        <Loading />
      ) : (
        <div className="page-content company-page">
          <div className="page-content-title border-bottom-title mb-[30px] flex items-center justify-between">
            <div className="inline-flex items-center cursor-pointer" onClick={handleGoBack}>
              <div className="mr-[17px]">
                <IconArrowLeft />
              </div>
              <span className="line-1">{'テンプレートの詳細'}</span>
            </div>
            <div className="flex items-center justify-end px-[12px] py-[8px]">
              <div className="flex gap-[20px] ml-auto">
                <Button className="button-add h-[48px] w-[115px]" onClick={handleGoToEditTemplate}>
                  <IconEdit className="mr-[10px]" />
                  編集
                </Button>
                <Button className="button-remove w-[115px] h-[48px]" onClick={handleOpenModalDelete}>
                  <IconTrash className="mr-[10px]" /> 削除
                </Button>
              </div>
            </div>
          </div>
          <Form form={antForm} className="form-create w-[900px] mx-auto" layout="vertical" autoComplete="off">
            <div className="pb-[12px]">
              <div className="mb-[16px]">
                <Form.Item required label="画像のサムネイル" name="thumbnail">
                  <div className="view-img-crop-detail-template">
                    <img src={detailData?.thumbnail_url} alt="thumbnail" className="thumbnail-upload" />
                  </div>
                </Form.Item>
              </div>
              <div className={`mb-[16px]`}>
                <Form.Item required label="テンプレート名" name="name" initialValue={detailData?.name}>
                  <Input className="input-form" placeholder="テンプレート名を入力してください" readOnly disabled />
                </Form.Item>
              </div>

              <div className="mt-[30px] bg-blue pt-[10px] pb-[20px] pr-[26px] pl-[26px]">
                <Form.Item
                  required
                  label="アクリル種類"
                  name="acrylic_plate"
                  className="pb-[20px]"
                  rules={[{ message: '', required: true }]}
                  initialValue={detailData?.acrylic_plate_size?.acrylic_plate_id}
                >
                  <Select
                    className={`select-type-part select-part-disable`}
                    options={newDataSelectAcrylicPlate || []}
                    placeholder="アクリル種類を選択してください"
                    disabled
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="サイズ"
                  name="acrylic_plate_size_id"
                  className="pb-[20px]"
                  rules={[{ message: '', required: true }]}
                  initialValue={detailData?.acrylic_plate_size_id}
                >
                  <Select
                    className={`select-type-part select-part-disable`}
                    options={newDataSelectSizeAcrylicPlate || []}
                    placeholder="サイズを選択してください"
                    onChange={(idSizeAcrylic: any) => {
                      const infoSizeAcrylicSelected = dataSelectSizeAcrylicPlate?.find(
                        (item: any) => item?.id === idSizeAcrylic
                      );
                      antForm.setFieldsValue({
                        height: infoSizeAcrylicSelected?.height,
                        width: infoSizeAcrylicSelected?.width,
                        thickness: infoSizeAcrylicSelected?.thickness,
                      });
                    }}
                    disabled
                  />
                </Form.Item>
                <div className="flex items-top gap-[30px]">
                  <Form.Item
                    required
                    label="高さ"
                    name={'height'}
                    className="w-[257px] error-form-acrylic"
                    initialValue={detailData?.acrylic_plate_size?.height}
                  >
                    <Input className="input-form" placeholder="ー" readOnly disabled />
                  </Form.Item>
                  <Form.Item
                    required
                    label="幅"
                    name={'width'}
                    className="w-[257px] error-form-acrylic"
                    initialValue={detailData?.acrylic_plate_size?.width}
                  >
                    <Input className="input-form" placeholder="ー" readOnly disabled />
                  </Form.Item>
                  <Form.Item
                    required
                    label="厚さ"
                    name={'thickness'}
                    className="w-[257px] error-form-acrylic"
                    initialValue={detailData?.acrylic_plate_size?.thickness}
                  >
                    <Input className="input-form" placeholder="ー" readOnly disabled />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="mb-[35px] flex items-top gap-[30px] mt-[20px] bg-blue pt-[10px] pb-[30px] pr-[26px] pl-[26px]">
              <Form.Item
                required
                label="単価（円）"
                name={'price'}
                className="w-[257px] error-form-acrylic"
                initialValue={detailData?.price}
              >
                <Input className="input-form" placeholder="例：500" readOnly disabled />
              </Form.Item>
              <Form.Item
                required
                label="配送料（円）"
                name={'shipping_fee'}
                className="w-[257px] error-form-acrylic"
                initialValue={detailData?.shipping_fee}
              >
                <Input className="input-form" placeholder="例：250" readOnly disabled />
              </Form.Item>
            </div>

            <div className="mb-[50px] flex items-top gap-[30px] mt-[20px] bg-blue pt-[10px] pb-[30px] pr-[26px] pl-[26px] wrap-design-image-template-preview">
              <Form.Item required label="画像情報">
                <div className="image-template-preview-detail">
                  <div className="cover-image-template">
                    {/* <img
                      style={{
                        height: `${heightImageDesign}px`,
                        width: `300px`,
                      }}
                      alt=""
                      src={detailData?.image_url}
                    /> */}
                    {svgImage && (
                      <div
                        // style={{ width: WIDTH_STAGE * (75 / HEIGHT_STAGE) }}
                        dangerouslySetInnerHTML={{ __html: svgImage }}
                      />
                    )}
                  </div>
                  <div className="flex items-top gap-[20px] ">
                    <div
                      onClick={() => {
                        setIsOpenModalPreviewImage(true);
                      }}
                      className="icon-action"
                    >
                      <IconPreviewImage />
                    </div>
                    <a
                      onClick={() => handleDownload(detailData?.image_url, detailData?.image)}
                      target="_blank"
                      className="icon-action"
                    >
                      <IconDownloadImage />
                    </a>
                  </div>
                </div>
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
      <ModalConfirm
        isShowModal={isOpenModalDelete}
        onCancel={handleCloseModalDelete}
        onOk={handleConfirmDelete}
        content="削除確認"
        subContentPrefix="選択した「"
        subContent={detailData?.name}
        subContentSuffix="」を削除してもよろしいですか？"
        loadingButtonOk={isLoadingDelete}
        isClose={false}
        isIcon={false}
        textOk="削除"
      />
      <ModalPreviewFile
        isShowModal={isOpenModalPreviewImage}
        onCancel={() => {
          setIsOpenModalPreviewImage(false);
        }}
        width={300}
        svgImage={svgImage}
      />
    </>
  );
};

export default TemplateDetailComponent;
